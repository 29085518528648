import axios from 'axios';

const utf8_to_b64 = (str) => {
  return window.btoa(unescape(encodeURIComponent(str)));
}

const b64_to_utf8 = (str) => {
  try {
    return window.atob(decodeURIComponent(str))
    // return decodeURIComponent(escape(window.atob(str)));
  } catch(err) {
    console.log(err);
    return str;
  }
}

var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return false;
};

const token = b64_to_utf8(getUrlParameter('uid'));
const vmId = b64_to_utf8(getUrlParameter('vmId'));
const cId = b64_to_utf8(getUrlParameter('cId'));
const baseUri = 'https://api.agrid.io';

export const getData = () => {
  return new Promise(function(resolve, reject) {
    const uri = baseUri + '/virtual-machine/' + vmId;
    return axios.get(uri, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err);
    });
  });
};

export const listData = () => {
  return new Promise(function(resolve, reject) {
    const uri = baseUri + '/list-data/' + cId;
    return axios.get(uri, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err);
    });
  });
};

export const saveData = (sources, keywords, blacklist, emails) => {
  return new Promise(function(resolve, reject) {
    const uri = baseUri + '/configure-emails/' + cId;
    return axios.post(uri, {
      sources: sources,
      keywords: keywords,
      blacklist: blacklist,
      emails: emails
    }, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err);
    });
  });
};
