import React from 'react';
import styled, { keyframes } from 'styled-components';


const mymove = keyframes`
  50% {
    transform: scale(2);
    opacity: 0
  }
  100% {
    transform: scale(2);
    opacity: 0
  }
`;

const Container = styled.div`
  height: 100%;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Circle = styled.div`
  content: " ";
  margin: 15px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 auto;
  transition: all 0.3s;
  background-color: #1F51FF;
  &::before {
    content: " ";
    margin: 15px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 0 auto;
    transition: all 0.3s;
    background-color: #1F51FF;

    animation: ${mymove} 2s infinite;
    position: absolute;
    background-color: #00FF00
  }
`;
const Text = styled.span`

`;


function ServerStatus() {
  return (
    <Container>
      <Text>Cloud status:</Text> <Circle></Circle> <Text style={{fontWeight: 'bold'}}>Active</Text>
    </Container>
  );
}

export default ServerStatus;
