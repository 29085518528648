import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Logo from '../../statics/logo.svg';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import { styled as muiStyled } from '@mui/system';
import Stack from '@mui/material/Stack';
import { saveData } from '../../api/API';

const blue = {
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
};

const grey = {
  100: '#eaeef2',
  300: '#afb8c1',
  900: '#24292f',
};

const CustomButton = muiStyled(ButtonUnstyled)(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  background-color: ${blue[500]};
  padding: 12px 24px;
  border-radius: 12px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;
  box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[100]};

  &:hover {
    background-color: ${blue[600]};
  }

  &.${buttonUnstyledClasses.active} {
    background-color: ${blue[700]};
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 3px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
  `,
);



const Container = styled.div`

`;

const Title = styled.p`
  text-decoration: underline;
`;

function Settings(props) {
  const [sources, setSources] = useState('');
  const [keywords, setKeywords] = useState('');
  const [blacklist, setBlacklist] = useState('');
  const [emails, setEmails] = useState('');
  const [dataFetched, setDataFetched] = useState(false);

  const setData = () => {
    setSources(props.sources.length >= 1 ? props.sources.join(',') : '');
    setKeywords(props.keywords.length >= 1 ? props.keywords.join(',') : '');
    setBlacklist(props.blacklist.length >= 1 ? props.blacklist.join(',') : '');
    setEmails(props.emails.length >= 1 ? props.emails.join(',') : '');
  };

  const saveSettings = () => {
    return saveData(sources, keywords, blacklist, emails).then(res => {
      alert('Data saved!');
      window.location.reload();
    });
  };

  useEffect(() => {
    if (!dataFetched) {
      setData();
      setDataFetched(true);
    }
  }, [sources]);

  return (
    <Container>
      <Title>Sources:</Title>
      <TextareaAutosize value={sources} onChange={(e) => setSources(e.target.value)} rows={5} style={{width: 500, height: 100}}/>
      <Title>Keywords:</Title>
      <TextareaAutosize value={keywords} onChange={(e) => setKeywords(e.target.value)} rows={5} style={{width: 500, height: 100}}/>
      <Title>Blacklist:</Title>
      <TextareaAutosize value={blacklist} onChange={(e) => setBlacklist(e.target.value)} rows={5} style={{width: 500, height: 100}}/>
      <Title>Emails:</Title>
      <TextareaAutosize value={emails} onChange={(e) => setEmails(e.target.value)} rows={5} style={{width: 500, height: 100}}/>
      <br /><br />
      <CustomButton onClick={() => saveSettings()}>Save</CustomButton>
      <br />
    </Container>
  );
}

export default Settings;
