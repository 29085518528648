import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Header from '../../components/Header';
import Table from '../../components/Table';
import SettingsComponent from '../../components/Settings';
import Loader from '../../components/Loader';
import ServerStatus from '../../components/ServerStatus';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import SyncIcon from '@mui/icons-material/Sync';
import { getData, listData } from '../../api/API';

const Container = styled.div`
`;

const Wrapper = styled.div`
  padding-left: 10%;
  padding-top: 70px;
`;

const MainTitle = styled.h1`
  text-decoration: underline;
`;

const Settings = styled(SettingsIcon)`
  text-decoration: none;
  font-size: 27px !important;
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    color: #5a13ff;
  }
`;
const Close = styled(CloseIcon)`
  text-decoration: none;
  font-size: 27px !important;
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    color: #5a13ff;
  }
`;
const Sync = styled(SyncIcon)`
  text-decoration: none;
  font-size: 27px !important;
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    color: #5a13ff;
  }
`;
const TableContainer = styled.div`
  width: 90%;
`;
const LastUpdatedAtText = styled.p`
  font-weight: 500;
  margin-bottom: 1px;
`;


function HomePage() {
  const [openSettings, setOpenSettings] = useState(false);
  const [data, setData] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [sources, setSources] = useState([]);
  const [blacklist, setBlacklist] = useState([]);
  const [emails, setEmails] = useState([]);
  const [seeData, setSeeData] = useState(false);


  const [error, setError] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [unsetLength, setUnsetLength] = useState(0);
  const [clientName, setClientName] = useState('');

  const update = () => {
    alert('Data updating..')
    getOpenDB();
  }

  const getOpenDB = () => {
    setIsLoading(true);
    return getData().then(data => {
      data = data.output;
      const machine = data.machine;
      const client = data.client;

      const keywords_ = client.keywords;
      const sources_ = client.sources;
      const blacklist_ = client.blacklist;
      const emails_ = client.emails;

      setKeywords(keywords_);
      setSources(sources_);
      setBlacklist(blacklist_);
      setEmails(emails_);
      setClientName(client.clientName)

      return listData().then(data => {
        setData(data['data'].reverse())

        let i = 0;
        let ii = 0;
        for (let d in data['data']) {
          if (!data['data'][d].dataDownload) {
            i = i + 1
          }
        }
        setUnsetLength(i);
        setIsLoading(false);
      }).catch(err => {
        console.log(err);
        setError(true);
        setIsLoading(false);
      });

    }).catch(err => {
      console.log(err);
      setError(true);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (!dataFetched) {
      getOpenDB();
      listData();
      setDataFetched(true);
    }
  }, []);

  return (
    <Container>
      <Header />

      <Wrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {error ? (
              <MainTitle>Error connecting to server..</MainTitle>
            ) : (
              <>
                <>
                  {clientName && (
                    <h1><u>{clientName}</u></h1>
                  )}

                  {data && data.length >= 1 && (
                    <>
                      {seeData ? (
                        <button style={{backgroundColor: '#0059B2', border: '1px solid #0059B2', color: 'white', padding: 5, borderRadius: 10, fontSize: 20}} onClick={() => window.location.reload()}>Hide data</button>
                      ) : (
                        <button style={{backgroundColor: '#0059B2', border: '1px solid #0059B2', color: 'white', padding: 5, borderRadius: 10, fontSize: 20}} onClick={() => setSeeData(true)}>Show data</button>
                      )}
                      <h3>Data points: {data.length}</h3>
                      <h3>Data points unsent: {unsetLength}</h3>
                      <h3>Last data captured: {data[0].createdAt.toString()}</h3>
                    </>
                  )}
                </>
                <SettingsComponent sources={sources} keywords={keywords} blacklist={blacklist} emails={emails}/>
                <br />
                {seeData && data && (
                  <>
                    <br /><br />
                    <Table data={data.slice(0, 100)} />
                  </>
                )}
              </>
            )}
          </>
        )}
      </Wrapper>
    </Container>
  );
}

export default HomePage;
