import React from 'react';
import styled from 'styled-components';
import Logo from '../../statics/logo.svg';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';

const Container = styled.div`
  height: 70px;
  width: 100%;
  background-color: #5a13ff;
  align-items: center;
`;

const Wrapper = styled.div`
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const AgridLogo = styled.img`
  width: 150px;
  margin: auto;
  margin-top: 10px;
`;

const ContactUs = styled(ContactSupportIcon)`
  font-size: 50px !important;
  color: white;
  margin-top: 10px;
  cursor: pointer;
  &:hover {
  }
`;


function Header() {
  return (
    <Container>
      <Wrapper>
        <div>
          <AgridLogo src={Logo} />
        </div>

        <div></div>
      </Wrapper>
    </Container>
  );
}

export default Header;
